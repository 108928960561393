import styled from "styled-components"

export const CardDetails = styled.div`
  h3,
  p {
    margin: 0;
  }

  h3 {
    font-size: 1.5rem;
    color: #333333;
    letter-spacing: 0;
    font-weight: 400;
  }

  p {
    font-size: 1rem;
    letter-spacing: 0.59px;

    span {
      text-transform: uppercase;
    }
  }
`
