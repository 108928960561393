import styled from "styled-components"
import { sm, md } from "mill/utils/breakpoints"

export const Container = styled.div`
  margin-top: 2rem;
  background: white;
`

export const Tabs = styled.div`
  background: white;
  border-bottom: 1px solid #f6f6f6;
  border-radius: 3px 3px 0 0;
  height: 8rem;
  background: #ffffff;
  display: flex;
`

export const Tab = styled.div`
  font-size: 1.6rem;
  letter-spacing: 0;
  text-align: center;
  height: 100%;
  width: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: white;
  color: #8e9091;

  i {
    margin-right: 1rem;
    font-size: 2.5rem;

    @media ${sm} {
      margin-right: 1.5rem;
      font-size: 3rem;
    }
  }

  ${props =>
    props.isActive &&
    `
    background: #FCFCFC;
    color: ${props.theme.colors.primaryHover};
    border-bottom: 3px solid ${props.theme.colors.primary};
  `}
`

export const LearnerContainer = styled.div`
  background: white;
  border-radius: 2px;
  padding: 1rem;

  h2 {
    font-family: Raleway;
    font-size: 2rem;
    color: #4b5252;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }

  @media ${sm} {
    padding: 2rem;
  }
`

export const LearnerHeading = styled.div`
  background: #f7f8f9;
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  font-size: 1.4rem;
  color: #535353;
  letter-spacing: 1px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;

  p {
    display: inline-block;
    margin: 0.5rem 0;

    &:nth-of-type(2) {
      text-align: right;
      width: 15rem;

      @media ${md} {
        text-align: left;
      }
    }
  }
`

export const LearnerCard = styled.div`
  background: #ffffff;
  border: 1px solid #f7f8f9;
  border-radius: 2px;
  margin: 1rem 0;
  padding: 1rem 0 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    flex-grow: 1;
  }

  > p {
    width: 15rem;
    text-align: right;
    padding-right: 1rem;

    @media ${md} {
      text-align: left;
    }
  }
`
