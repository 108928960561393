import React from "react"
import styled from "styled-components"
import { lighten } from "polished"
import colors from "shared/utils/colors"

export const StatusCircle = styled.span`
  width: 2rem;
  height: 2rem;
  margin: auto 1rem auto 0;
  border-radius: 50%;
  background: ${colors.grayAthens};
  border: 0.5rem solid ${colors.graySolitude};
  ${props =>
    props.warmup &&
    `background: ${props.theme.colors.secondary};
    border: 0.5rem solid ${lighten(0.1, props.theme.colors.secondary)};`}
  ${props =>
    props.active &&
    `background: ${props.theme.colors.primary};
    border: 0.5rem solid ${lighten(0.1, props.theme.colors.primary)};`}
    ${props =>
    props.cooldown &&
    `background: ${colors.blueMaya};
    border: 0.5rem solid ${lighten(0.1, colors.blueMaya)};`};
`

const CampaignStatusCircle = ({ state }) => {
  const warmup = state === "warmup"
  const active = state === "active"
  const cooldown = state === "cooldown"

  return <StatusCircle warmup={warmup} active={active} cooldown={cooldown} />
}

export default CampaignStatusCircle
