import styled from "styled-components"
import { lg } from "mill/utils/breakpoints"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  input {
    border: 1px solid #eaeaea;
  }
`

export const Scrollable = styled.div`
  overflow: auto;
  position: absolute;
  top: 100px;
  bottom: 0px;
  left: 0;
  right: 0;

  > div {
    border-bottom: 1px solid #eee;
    &:last-of-type {
      border-bottom: none;
    }
  }
`

export const Learner = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem 0;

  p {
    color: #aeaeae;
    margin: 0;
    font-size: 1.5rem;
  }

  > div:first-of-type {
    flex: 3;
    color: #5c6c70;

    p:first-of-type {
      margin-bottom: 0.3rem;
    }

    span {
      margin-left: 1.5rem;
    }

    strong {
      font-weight: 400;
      color: #535353;
    }
  }

  > div:last-of-type {
    display: none;
    text-align: right;
    margin-left: 1rem;
    
    @media ${lg} {
      display: flex;
      justify-content: end;
      align-items: center;
      flex: 1;
    }
  }
`
