import React from "react"
import styled from "styled-components"
import { bodyFont } from "mill/utils/settings"
import colors from "shared/utils/colors"

const Container = styled.div`
  display: flex;
  ${props =>
    !props.full &&
    `
    max-width: 60rem;
    min-width: 30rem;
  `};
`

const Input = styled.input`
  flex: 1;
  line-height: 4rem;
  height: 4rem;
  border-radius: 3px;
  border-color: ${colors.graySilver};
  border-width: 1px;
  border-style: solid;
  padding: 0.6rem 1.3rem;
  font-size: 1.5rem;
  font-family: ${bodyFont};
  line-height: 2.2rem;
  transition: all 0.2s ease-out;
  color: ${colors.grayAluminium};
  ${props =>
    props.includeIcon &&
    `border-radius: 0 3px 3px 0;
    border-left: none;`};
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    transition: all 0.2s ease-in;
  }
`
const IconContainer = styled.div`
  background-color: ${props => props.theme.colors.primary};
  width: 4rem;
  border-radius: 3px 0 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`

const Icon = styled.i`
  color: white;
`

const SearchIcon = () => {
  return (
    <IconContainer>
      <Icon className="fa fa-search" />
    </IconContainer>
  )
}

const SearchBox = props => {
  return (
    <Container full={props.full}>
      {props.includeIcon && <SearchIcon />}

      <Input
        name={props["name"] || "search"}
        autoFocus={props.autoFocus}
        includeIcon={props.includeIcon}
        data-qa={props["data-qa"]}
        placeholder={props.placeholder ? props.placeholder : "Search"}
        onChange={props.handleSearch}
        value={props.searchTerm}
      />
    </Container>
  )
}

export default SearchBox
