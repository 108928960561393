import styled from "styled-components"
import { sm, lg } from "mill/utils/breakpoints"

export const DashboardHeader = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;

  a {
    margin-top: 1rem;
    display:: inline-block;
    position: relative;
    top: 2rem;
  }

  p,
  a {
    color: ${props => props.theme.colors.primary};
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }

    i {
      padding-left: 1rem;
      transform: rotate(180deg);
    }
  }

  ${props =>
    props.menuOpen &&
    `
    p, h1 {
      @media ${sm} {
        transform: translate(33rem);
        transition: transform 0.5s;
        -webkit-transition: transform 0.5s;
      }

      @media ${lg} {
        transform: none;
      }
    }
  `}

  @media ${sm} {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  h1 {
    text-transform: uppercase;
    color: #707070;
    font-family: Raleway;
    font-weight: 600;
    font-size: 3.2rem;
    letter-spacing: 2.4px;

    span {
      color: #adadad;
    }
  }
`

export const Card = styled.div`
  background: white;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 1.5rem 2rem;
  margin-top: 2rem;
  color: #909090;
  width: 100%;
`
