import React, { Fragment } from "react"
import { useQuery } from "@apollo/client"
import FETCH_TEAMS_AND_GROUPS_UNDER_MANAGEMENT from "mill/graphql/FetchTeamsAndGroupsUnderManagement"
import { Menu, TeamItem } from "./styles"

// Fetch viewer.teams / viewer.groups
const TeamNavigator = ({ isOpen, closeMenu, clusterId, clusterType }) => {
  const { loading, data } = useQuery(FETCH_TEAMS_AND_GROUPS_UNDER_MANAGEMENT)
  const isActive = (group, id) => {
    return (group == clusterType && id == clusterId).toString()
  }

  return (
    <Menu isOpen={isOpen} data-qa="other-team-menu">
      <i className="fa fa-cross" onClick={closeMenu} />

      {data?.viewer?.groupsUnderManagement.length > 0 && (
        <Fragment>
          <h2>Groups</h2>
          {!loading &&
            data?.viewer?.groupsUnderManagement?.map(group => {
              return (
                <TeamItem
                  key={group.id}
                  isactive={isActive("team-group", group.id)}
                  to={`/team-group/${group.id}`}
                  onClick={closeMenu}>
                  {group.name}
                </TeamItem>
              )
            })}{" "}
        </Fragment>
      )}

      <h2>Teams</h2>
      {!loading &&
        data?.viewer?.teamsUnderManagement?.map(team => {
          return (
            <TeamItem
              key={team.id}
              isactive={isActive("team", team.id)}
              to={`/team/${team.id}`}
              onClick={closeMenu}>
              {team.name}
            </TeamItem>
          )
        })}
    </Menu>
  )
}

export default TeamNavigator
