import styled from "styled-components"
import { lg } from "mill/utils/breakpoints"

export const Container = styled.div`
  max-height: 50rem;
  overflow: auto;
`

export const Card = styled.div`
  background: white;
  text-align: left;
  border-bottom: 1px solid #f7f8f9;
  padding: 1.5rem 2rem;
  color: #909090;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`

export const Stat = styled.div`
  font-size: 1.5rem;
  color: #333333;
  margin-right: 5rem;
  display: none;

  span {
    display: block;
    font-size: 1rem;
    color: #b7babd;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  @media ${lg} {
    display: block;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
