import styled from "styled-components"
import Link from "shared/components/Link"
import { sm } from "mill/utils/breakpoints"
import zIndex from "shared/utils/zIndexLevels"

export const Menu = styled.div`
  background: white;
  max-width: 85%;
  padding: 2rem 1rem;
  position: fixed;
  top: 5rem;
  left: -85%;
  bottom: 0;
  right: 0;
  min-height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  overflow: auto;
  display: inline-block;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  z-index: ${zIndex.menu};
  ${props =>
    props.isOpen &&
    `
    transform: translateX(calc(85vw));
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    box-shadow: 2px 0 9px 0 rgba(0,0,0,0.11);
  `}

  h2 {
    margin-top: 0.5rem;
  }

  i {
    position: absolute;
    top: 2rem;
    right: 1rem;
    font-size: 3rem;
    cursor: pointer;
    color: ${props => props.theme.colors.primary};
  }

  @media ${sm} {
    max-width: 33rem;
    min-width: 33rem;
    left: -33rem;
    padding: 2rem;
    ${props =>
      props.isOpen &&
      `
      transform: translateX(33rem);
      transition: transform 0.5s;
      -webkit-transition: transform 0.5s;
    `}
  }
`

export const TeamItem = styled(Link)`
  font-size: 1.6rem;
  font-weight: 600;
  color: #4b5252;
  letter-spacing: 0.5px;
  padding: 1rem 2rem;
  margin: 0 -2rem;
  display: block;

  ${props =>
    props.isactive === "true" &&
    `
    background: #F7F8F9;
    color: ${props.theme.colors.primary};
  `}
`
