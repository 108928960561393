import React from "react"
import Heading from "shared/components/Heading"
import Box from "shared/components/Box"
import Button from "shared/components/Button/index.legacy.js"
import CampaignStatusCircle from "mill/components/CampaignStatusCircle"
import { Container, Card, FlexContainer, Stat } from "./styles"
import { CardDetails } from "../sharedStyles"

const TeamCampaigns = ({ title, campaigns, clusterId, clusterType }) => {
  const activeCampaign = campaign => {
    return ["warmup", "active", "cooldown"].includes(campaign.state)
  }

  return (
    <Container>
      <Box paddingTop={"small"} paddingBottom={"small"}>
        <Heading level={3}>{title}</Heading>
      </Box>
      {campaigns.length > 0 ? (
        campaigns.map(campaign => {
          return (
            <Card key={campaign.id} data-qa={campaign.id}>
              <FlexContainer>
                {activeCampaign(campaign) && (
                  <CampaignStatusCircle state={campaign.state} />
                )}
                <CardDetails>
                  <h3>{campaign.name}</h3>
                  <p>
                    <span>{campaign.mechanism}</span>:{" "}
                    {campaign.friendlyStartDate} -{" "}
                    {campaign.friendlyCompletionDate}
                  </p>
                </CardDetails>
              </FlexContainer>
              <FlexContainer>
                <Stat>
                  {campaign.viewFor.participation}% <span>Participation</span>
                </Stat>
                <Stat>
                  {campaign.viewFor.performance}% <span>Performance</span>
                </Stat>
                <Button
                  data-qa={`view-campaign-${campaign.id}`}
                  to={`/${clusterType}/${clusterId}/c/${campaign.id}`}
                  label="View"
                  secondary
                  hollow
                  tiny
                  style={{ minWidth: "5rem" }}
                />
              </FlexContainer>
            </Card>
          )
        })
      ) : (
        <p>No {title.toLowerCase()}</p>
      )}
    </Container>
  )
}

export default TeamCampaigns
